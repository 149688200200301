<template>

  <div class="header-bg w-full flex justify-center items-center">
    <nav
    class="container flex flex-col"
    role="navigation"
    aria-label="main navigation"
    >

      <div class="flex flex-wrap justify-around p-2 items-center w-full">

        <div
          class="flex flex-wrap uppercase text-center justify-center cursor-pointer"
          @click="navigateTo('services')"
        >
          <div class="flex text-4xl font-bold text-white">
            Printotype
          </div>
          <div class="text-sm text-white w-full">
            making ideas matter
          </div>
        </div>

        <div class="flex flex-wrap justify-center py-4">

          <router-link
            to="/services"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'services' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            Services
          </router-link>

          <!-- <router-link
            to="/news"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'news' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            News
          </router-link> -->

          <router-link
            to="/3d-printing"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == '3d-printing' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            3D Printing
          </router-link>

          <router-link
            to="/portfolio"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'portfolio' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            Portfolio
          </router-link>

          <router-link
            to="/about"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'about' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            About
          </router-link>

          <router-link
            to="/pricing"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'pricing' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            Pricing
          </router-link>

          <!-- <router-link
            to="/e-store"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'e-store' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            E-store
          </router-link> -->

          <router-link
            to="/contact"
            class="p-2 mx-2 uppercase text-lg transition-all ease-in-out duration-200"
            :class="currentRoute == 'contact' ? 'bg-white text-black' : 'hover:bg-white hover:text-black text-white'"
            >
            Contact
          </router-link>

        </div>

      </div>

      <hr />

      <div class="flex flex-col text-white text-center items-center p-2 text-xl uppercase mt-6">
        <div>
          Printotype specializes in 3D Design & Rapid Prototyping
        </div>

        <div class="flex justify-center">
          <div
            @click="navigateTo('contact')"
            class="bg-pt-red text-white uppercase text-sm p-2 px-4 mt-4 cursor-pointer"
          >
            Contact Us
          </div>
        </div>
      </div>

    </nav>
  </div>

</template>


<script>

  import { mapGetters } from 'vuex';

  export default {
    name: 'Nav',
    methods: {
      navigateTo(name) {
        this.$router.push({ name: name })
      }
      // login() {
      //   this.$auth.loginWithRedirect();
      // },
      // logout() {
      //   this.$auth.logout({
      //     returnTo: window.location.origin
      //   });
      // }
    },
    computed: {
      ...mapGetters([
        'getAuthStatusX',
        'getAppLoadedX'
      ]),
      currentRoute() {
        return this.$route.name;
      },
    }
  }

</script>

<style scoped>

  .header-bg {
    background: url(../../assets/images/header.png) no-repeat center;
    background-size: cover;
  }

</style>