<template>
  <div class="flex flex-col bg-pt-gray">

    <div class="flex justify-start container text-pt-gray-text p-4">

      <div class="mx-4 flex flex-col text-center">
        <div class="uppercase font-bold text-2xl">Printotype</div>
        <div class="text-center uppercase text-xs">Making ideas matter</div>
      </div>

      <div class="mx-4 flex flex-col">
        <router-link
          to="/services"
          class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
          >
          Services
        </router-link>

        <!-- <router-link
          to="/news"
          class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
          >
          News
        </router-link> -->

        <router-link
          to="/3d-printing"
          class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
          >
          3D Printing
        </router-link>

        <router-link
          to="/portfolio"
          class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
          >
          Portfolio
        </router-link>
      </div>

      <div class="mx-4 flex flex-col">
        <router-link
            to="/about"
            class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
            >
            About
          </router-link>

          <router-link
            to="/pricing"
            class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
            >
            Pricing
          </router-link>

          <!-- <router-link
            to="/e-store"
            class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
            >
            E-store
          </router-link> -->

          <router-link
            to="/contact"
            class="p-2 uppercase text-xs transition-all ease-in-out duration-200 text-pt-gray-text hover:underline hover:text-white"
            >
            Contact
          </router-link>
      </div>

      <div class="flex flex-col mx-12 p-2 text-center">
        <div class="uppercase font-bold">
          follow us
        </div>
        <div class="flex justify-center">

          <a
            href="https://www.facebook.com/Printotype"
            class="hover:text-white transition-all duration-200 ease-in-out mx-2"
            target="_blank"
          >
            <i class="fab fa-facebook-square fa-2x"></i>
          </a>

          <a
            href="https://twitter.com/PrintotypeLtd"
            class="hover:text-white transition-all duration-200 ease-in-out mx-2"
            target="_blank"
          >
            <i class="fab fa-twitter fa-2x"></i>
          </a>

          <!-- <a
            href="https://www.printotype.co.uk/services#"
            class="hover:text-white transition-all duration-200 ease-in-out mx-2"
            target="_blank"
          >
            <i class="fab fa-youtube fa-2x"></i>
          </a>

          <a
            href="https://www.printotype.co.uk/services#"
            class="hover:text-white transition-all duration-200 ease-in-out mx-2"
            target="_blank"
          >
            <i class="fab fa-instagram fa-2x"></i>
          </a> -->

        </div>
      </div>

    </div>

    <div class="bg-pt-subfooter flex justify-around items-center text-xs p-2 text-pt-gray-text uppercase">
      <div>
        Printotype ltd @ 2015-{{ thisYear }}
      </div>
      <div>
        created with <i class="fas fa-heart"></i> by
        <a
          href="https://blackflamedigital.com"
          target="_blank"
          class="hover:text-white hover:underline transition-all duration-200 ease-in-out"
        >
          Black Flame Digital
        </a>
      </div>
    </div>

  </div>

</template>

<script>

  import { format } from 'date-fns';

  export default {
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {
      thisYear() {
        return format(new Date(), 'yyyy');
      }
    }
  }

</script>