<template>
  <div id="main-container" class="flex flex-col min-h-screen">

    <Nav />

    <!-- MAIN CONTENT -->
    <main class="flex flex-grow">

      <router-view v-slot="slotProps">
        <transition enter-active-class="animate__animated animate__faster animate__fadeIn" leave-active-class="animate__animated animate__faster animate__fadeOut" name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>

    </main>

    <!-- Footer -->
    <Footer />

  </div>

</template>

<script>

import Nav from './Nav';
import Footer from './Footer';

export default {
  components: {
    Nav,
    Footer
  }
}
</script>


